import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'Store/index';
import ThemedDialog from '../Dialog';
import ContactForm from './ContactForm';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { StarsInput } from './components';
import TextArea from 'Components/InputHandler/components/TextArea';
import { actions } from 'Store/session';

const StyledButtonArea = styled('div')({ margin: '2rem 0', display: 'block' });

const StyledQuestionText = styled('h3')(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: '1.5rem',
  fontSize: '2rem',
}));

const StyledQuestionArea = styled('div')({ '* > &:not(:last-of-type)': { marginBottom: '4rem' } });

export const SurveyDialog = ({ open, survey, setOpenDialog }: ISurveyDialog) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [surveyAnswers, setSurveyAnswers] = React.useState<Record<string, any>>({});
  const onChange = (name: string) => (value: any) => {
    setSurveyAnswers({ ...surveyAnswers, [name]: value });
  };

  const dispatch = useAppDispatch();

  const sendFeedback = () => {
    dispatch(actions.surveyAnswerAction(survey.survey_id, surveyAnswers));

    setOpenDialog(false);
  };

  const questionIDs = Object.keys(survey.questions);

  return (
    <ThemedDialog
      open={open}
      title={survey.title || fm('header.survey')}
      onClose={setOpenDialog(false)}
      dialogActions={[
        { text: 'general.cancel', onClick: setOpenDialog(false) },
        {
          text: 'header.feedbackForm.send',
          onClick: () => {
            sendFeedback();
          },
          filled: true,
        },
      ]}
    >
      <div style={{ width: '75%', margin: '0 auto' }}>
        {questionIDs.map((questionID) => {
          const questionType = survey.questions[questionID].answer_schema;
          //const questionText = survey.questions[questionID].question_localized[locale as Locale];
          const questionText = survey.questions[questionID].question_localized;
          return (
            <StyledQuestionArea key={questionID}>
              <StyledQuestionText>{questionText}</StyledQuestionText>
              <div style={{ marginTop: '0rem' }}>
                {questionType === 'fiveStar' ? (
                  <div style={{ margin: '0 10rem' }}>
                    <StarsInput
                      name={questionID}
                      starCount={5}
                      value={surveyAnswers[questionID]}
                      onChange={onChange(questionID)}
                    />
                  </div>
                ) : questionType === 'freeText' ? (
                  <TextArea
                    type="TextArea"
                    editing
                    name={questionID}
                    value={surveyAnswers[questionID]}
                    onChange={(values) => onChange(questionID)(values[questionID])}
                    fullWidth
                    placeholder={questionText}
                  />
                ) : null}
              </div>
            </StyledQuestionArea>
          );
        })}

        <div style={{ fontWeight: 600 }}>{fm('header.answersAreAnonymous')}</div>
      </div>
    </ThemedDialog>
  );
};

interface ISurveyDialog {
  open: boolean;
  survey: IUserSurvey;
  setOpenDialog: (open: boolean) => () => void;
}

export const SurveyContactMenuChooser = ({ openDialog, setOpenDialog }: ISurveyContactMenuChooser) => {
  const survey = useAppSelector((state) => state.session.survey);

  const [openDialogType, setOpenDialogType] = React.useState<'survey' | 'contact' | null>(survey ? null : 'contact');
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const openSurvey = () => {
    setOpenDialogType('survey');
  };

  return openDialog ? (
    <>
      {openDialogType === null ? (
        <ThemedDialog
          open
          title={fm('header.feedback')}
          onClose={setOpenDialog(false)}
          dialogActions={[{ text: 'general.close', onClick: setOpenDialog(false) }]}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              {survey && (
                <StyledButtonArea key={survey.survey_id}>
                  <ActionButtonRounded
                    filled
                    text={
                      <div className="ellipsis" style={{ maxWidth: '24rem' }}>
                        {survey.title || fm('header.survey')}
                      </div>
                    }
                    fontSize={16}
                    width={24}
                    height={4}
                    onClick={() => openSurvey()}
                  />
                </StyledButtonArea>
              )}
              <StyledButtonArea>
                <ActionButtonRounded
                  filled
                  text="header.sendFeedback"
                  fontSize={16}
                  width={24}
                  height={4}
                  onClick={() => setOpenDialogType('contact')}
                />
              </StyledButtonArea>
            </div>
          </div>
        </ThemedDialog>
      ) : openDialogType === 'survey' && survey ? (
        <SurveyDialog open={true} survey={survey} setOpenDialog={setOpenDialog} />
      ) : (
        <ContactForm openDialog setOpenDialog={setOpenDialog} />
      )}
    </>
  ) : null;
};

interface ISurveyContactMenuChooser {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => () => void;
}
