import { styled } from '@mui/material';
import * as React from 'react';

import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';

const StyledBarArea = styled(Container)(({ theme }) => ({
  height: '6rem',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.primary.dark,
}));

const StyledBarContent = styled(Item)({ marginRight: '2rem' });

const StyledBarButtons = styled(Item)({
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    margin: '0 0.5rem',
  },
});

const NotificationBar = ({ content, buttons }: INotificationBar) => {
  return (
    <StyledBarArea justifyContent={'center'}>
      <StyledBarContent xs={true} md={'auto'}>
        {content}
      </StyledBarContent>
      {buttons && (
        <StyledBarButtons xs={'auto'} md={'auto'}>
          {buttons.map((but, i) => (
            <React.Fragment key={i}>
              <ActionButtonRounded {...but} />
            </React.Fragment>
          ))}
        </StyledBarButtons>
      )}
    </StyledBarArea>
  );
};

interface INotificationBar {
  content: JSX.Element;
  buttons?: IActionButtonRounded[];
}

export default NotificationBar;
